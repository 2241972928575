import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HotkeysService } from 'angular2-hotkeys';
import { AppUiReduxObject } from '../state/actions/ui-actions';
import { HotKeyRegistrationServiceCommon } from './hotkey-registration-service.common';

@Injectable({
  providedIn: 'root'
})
export class HotKeyRegistrationService {
  constructor(
    private _hotkeysService: HotkeysService,
    private _appUiReduxObject: AppUiReduxObject,
    private _store: Store<any>
  ) {}
  registerHotkeys() {
    HotKeyRegistrationServiceCommon.registerHotkeys(this._hotkeysService, this._appUiReduxObject, this._store);
  }
}
